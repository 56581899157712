import styled from 'styled-components'

export const StyledAvatarReviewer = styled.div`
  position: relative;
  img {
    border-radius: 50%;
  }
  .avatar {
    object-fit: cover;
  }
  .badge {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
  }
`

export const StyledTinyBadge = styled.div`
  width: 12px;
  height: 12px;
`
