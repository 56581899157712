import React from 'react'

import { Heading, theme } from '@hhgtech/hhg-components'
import { CommonGAssets } from '@hhgtech/hhg-components/gAssets'
import { Text } from '@hhgtech/hhg-components/mantine'
import { CSSProperties } from 'styled-components'

import {
  StyledAnchor,
  StyledAuthor,
  StyledCard,
  StyledExpertName,
} from './index.styled'

type CardAuthorProps = {
  avatarImg: JSX.Element
  authorName: string
  authorLink?: string
  editorName?: string
  editorLink?: string
  size: string
  uploadTime: string
}

const CardAuthor = ({
  avatarImg,
  authorName,
  authorLink,
  uploadTime,
  size,
}: CardAuthorProps) => {
  return (
    <StyledAuthor>
      <div className="author">
        <div className="avatar" data-size={size}>
          {avatarImg}
        </div>
        <div className="poster">
          <Text size={size === 'lg' ? 'p3' : 'p4'}>
            <a href={authorLink || '#'}>{authorName} • </a>
            <span>{uploadTime}</span>
          </Text>
        </div>
      </div>
    </StyledAuthor>
  )
}

type ArticleExpertProps = {
  isMobile: boolean
  avatarImg: JSX.Element
  name: string
  link?: string
  size: 'lg' | 'md' | 'sm'
  specialty?: string
}

const CardExpert = ({
  isMobile,
  avatarImg,
  name,
  link,
  size = 'lg',
  specialty,
}: ArticleExpertProps) => {
  return (
    <StyledAuthor>
      <div className="author">
        <div className="expert-avatar" data-size={size}>
          {avatarImg}
        </div>
        <div className="expert">
          {isMobile ? (
            <a href={link || '#'}>
              {size === 'lg' ? (
                <>
                  <Heading
                    tag="h6"
                    className="expert__name"
                    color={theme.colors.gray600}
                  >
                    {name}
                  </Heading>
                  <Text
                    color={theme.colors.gray500}
                    size="p3"
                    className="specialty"
                  >
                    {specialty}
                  </Text>
                </>
              ) : (
                <>
                  <Heading
                    tag="h6"
                    className="expert__name"
                    color={theme.colors.gray600}
                  >
                    {name}
                  </Heading>
                </>
              )}
            </a>
          ) : (
            <a href={link || '#'}>
              {size === 'lg' ? (
                <>
                  <Heading
                    tag="h6"
                    className="expert__name"
                    color={theme.colors.gray600}
                  >
                    {name}
                  </Heading>
                  <Text
                    color={theme.colors.gray500}
                    size="p3"
                    className="specialty"
                  >
                    {specialty}
                  </Text>
                </>
              ) : (
                <>
                  <StyledExpertName
                    color={theme.colors.gray600}
                    className="expert__name"
                  >
                    {name}
                  </StyledExpertName>
                  <Text
                    color={theme.colors.gray500}
                    size="p4"
                    className="specialty"
                  >
                    {specialty}
                  </Text>
                </>
              )}
            </a>
          )}
        </div>
      </div>
    </StyledAuthor>
  )
}

export type Props = {
  banner: JSX.Element
  title: string
  text: string
  avatarImg: JSX.Element
  direction?: 'vertical' | 'horizontal'
  size?: 'sm' | 'lg' | 'md'
  type?: 'author' | 'expert'
  section?: 'home' | 'normal'
  authorName: string
  authorLink?: string
  specialty?: string
  uploadTime: string
  categoryName?: string
  categoryLink?: string
  articleLink?: string
  isBookmarkDisplayed?: boolean
  onBookmark?: () => void
  isBookmark?: boolean
  hasButton?: boolean
  textButton?: string
  isMobile?: boolean
  styleBanner?: CSSProperties
  styleLabel?: CSSProperties
  styleTitle?: CSSProperties
  style?: CSSProperties
  className?: string
  dataEventCategoryForCategory?: string
  dataEventActionForCategory?: string
  dataEventLabelForCategory?: string
  dataEventCategoryForTitle?: string
  dataEventActionForTitle?: string
  dataEventLabelForTitle?: string
  dataEventCategoryForContent?: string
  dataEventActionForContent?: string
  dataEventLabelForContent?: string
  dataEventCategoryForBanner?: string
  dataEventActionForBanner?: string
  dataEventLabelForBanner?: string
  dataEventCategoryForBookmark?: string
  dataEventActionForBookmark?: string
  dataEventLabelForBookmark?: string
  onClickCategoryName?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  showDescription?: boolean
  isThumbnailVideoUsed?: boolean
  headingType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  isPremium?: boolean
  headingAs?: string
}

const ArticleCard = ({
  isMobile = true,
  direction = 'horizontal',
  size = 'md',
  banner,
  isBookmarkDisplayed = false,
  type = 'author',
  // section = 'normal',
  onBookmark,
  categoryName,
  styleLabel,
  title,
  styleTitle,
  text,
  specialty,
  avatarImg,
  authorName,
  authorLink,
  articleLink,
  uploadTime,
  isBookmark = false,
  className,
  style,
  categoryLink,
  styleBanner,
  headingType,
  dataEventCategoryForCategory,
  dataEventActionForCategory,
  dataEventLabelForCategory,
  dataEventCategoryForTitle,
  dataEventActionForTitle,
  dataEventLabelForTitle,
  dataEventCategoryForContent,
  dataEventActionForContent,
  dataEventLabelForContent,
  dataEventCategoryForBanner,
  dataEventActionForBanner,
  dataEventLabelForBanner,
  dataEventCategoryForBookmark,
  dataEventActionForBookmark,
  dataEventLabelForBookmark,
  onClickCategoryName,
  showDescription = true,
  isThumbnailVideoUsed,
  isPremium,
  headingAs,
}: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    onBookmark && onBookmark()
  }

  const headingTag = headingAs || (size === 'lg' ? 'h3' : 'h4')
  const descriptionTag = 'p4'
  const categoryLabelTag = 'n1'

  const onClickCategory = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!!onClickCategoryName) onClickCategoryName(e)
  }

  return (
    <StyledCard
      className={`article-card ${className}`}
      data-size={size}
      style={style}
      data-direction={direction}
      data-is-expert={type === 'expert' || undefined}
    >
      <div className="banner" style={styleBanner}>
        <StyledAnchor
          data-event-category={dataEventCategoryForBanner}
          data-event-action={dataEventActionForBanner}
          data-event-label={dataEventLabelForBanner}
          href={articleLink || '#'}
        >
          {isThumbnailVideoUsed ? (
            <>
              {banner}
              <img
                loading="lazy"
                // onClick={(e) => {
                //   e.stopPropagation()
                // }}
                className="video-play-btn"
                src="/icons/video-play-button.svg"
                alt="Play"
              />
            </>
          ) : (
            banner
          )}
        </StyledAnchor>
        {isPremium && (
          <img
            loading="lazy"
            className="premium"
            src={CommonGAssets.getAssetPath('premium-crown.svg')}
          />
        )}
      </div>
      <div className="content">
        <div className="inner-content">
          <Text
            style={styleLabel}
            className="label"
            size={categoryLabelTag}
            weight="regular"
          >
            <StyledAnchor
              data-event-category={dataEventCategoryForCategory}
              data-event-action={dataEventActionForCategory}
              data-event-label={dataEventLabelForCategory}
              href={categoryLink || '#'}
              onClick={onClickCategory}
            >
              {categoryName}
            </StyledAnchor>

            {isBookmarkDisplayed && (
              <div
                data-event-category={dataEventCategoryForBookmark}
                data-event-action={dataEventActionForBookmark}
                data-event-label={dataEventLabelForBookmark}
                className="bookmark non-pointer-event-child"
                onClick={handleClick}
              >
                {!isBookmark ? (
                  <img
                    loading="lazy"
                    data-event-category={dataEventCategoryForBookmark}
                    data-event-action={dataEventActionForBookmark}
                    data-event-label={dataEventLabelForBookmark}
                    src="/icons/bookmark_line.svg"
                    style={{
                      width: 18,
                      height: 18,
                    }}
                  />
                ) : (
                  <img
                    loading="lazy"
                    data-event-category={dataEventCategoryForBookmark}
                    data-event-action={dataEventActionForBookmark}
                    data-event-label={dataEventLabelForBookmark}
                    src="/icons/bookmark_solid.svg"
                    style={{
                      width: 18,
                      height: 18,
                    }}
                  />
                )}
              </div>
            )}
          </Text>

          <Heading
            as={headingTag as any}
            style={styleTitle}
            className="title"
            tag={headingType ? headingType : size === 'lg' ? 'h3' : 'h4'}
          >
            <StyledAnchor
              data-event-category={dataEventCategoryForTitle}
              data-event-action={dataEventActionForTitle}
              data-event-label={dataEventLabelForTitle}
              href={articleLink || '#'}
            >
              {title}
            </StyledAnchor>
          </Heading>

          <div className="footer">
            {type === 'author' ? (
              <CardAuthor
                size={size}
                avatarImg={avatarImg}
                authorName={authorName}
                authorLink={authorLink}
                uploadTime={uploadTime}
              />
            ) : (
              <CardExpert
                size={size}
                isMobile={isMobile}
                avatarImg={avatarImg}
                specialty={specialty}
                name={authorName}
                link={authorLink}
              />
            )}
          </div>

          {showDescription && text && (
            <a
              href={articleLink || '#'}
              data-event-category={dataEventCategoryForContent}
              data-event-action={dataEventActionForContent}
              data-event-label={dataEventLabelForContent}
            >
              <Text size={descriptionTag} className="text">
                {text}
              </Text>
            </a>
          )}
        </div>
      </div>
    </StyledCard>
  )
}

export { ArticleCard }
