import { MediaQueries, ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledExpertName = styled.h6`
  color: ${(props: ThemeProps) => props.theme.colors.gray600};
  font-size: 14px;
  font-weight: ${(props: ThemeProps) => props.theme.sizes.fwBold};
  line-height: 22px;
`

export const StyledAnchor = styled.a`
  display: inline-block;
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none;

  & img.video-play-btn {
    position: absolute;
    right: 28px;
    bottom: 28px;
    width: 48px !important;
    height: 48px !important;
    pointer-events: auto !important;

    ${MediaQueries.mbDown} {
      right: 14px;
      bottom: 14px;
    }
  }

  /* * {
    pointer-events: none;
  } */
`

export const StyledCard = styled.article`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 16px 16px 0;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
  cursor: pointer;

  .content {
    display: flex;
    width: 100%;
    flex-direction: column;

    .inner-content {
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
      }

      & .title {
        margin-top: 8px;
        color: ${(props: ThemeProps) => props.theme.mbColors.dark};

        & > [data-tag='h3'] {
          font-family: Krub;
          font-weight: 600;
        }

        & > [data-tag='h4'] {
          font-weight: 500;
        }
      }

      .title a {
        display: -webkit-box;
        overflow: hidden;
        margin-top: 4px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .text {
        display: -webkit-box;
        overflow: hidden;
        max-width: 100%;
        margin-top: 4px;
        margin-bottom: 0 !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      & .label {
        display: flex;
      }
    }
  }

  .footer {
    display: flex;

    .author {
      width: calc(100% - 20px);
    }

    .bookmark {
      display: flex;
      width: 12px;
      height: 16px;
      margin-left: 8px;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .banner {
    position: relative;
    min-width: 100px;
    max-width: 100px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
    }
    .premium {
      position: absolute;
      width: 28px;
      height: 28px;
      top: 6px;
      left: 6px;
    }
  }

  &[data-size='sm'][data-direction='horizontal'] {
    padding: 20px 0;

    .label {
      margin-bottom: 0;
    }
    .banner {
      width: 100px;
      height: 100px;
      margin: 0 16px 0 0;

      ${MediaQueries.mbDown} {
        min-width: 100px;
        max-width: 100px;
        height: 100px;
        margin: 0 16px 0 0;
      }
    }

    .text {
      display: none;
    }
  }

  &[data-direction='horizontal'] {
    .footer {
      margin-top: auto;
    }
  }

  &[data-size='md'][data-direction='horizontal'] {
    padding: 16px 0;

    .banner {
      min-width: 200px;
      max-width: 200px;
      height: 150px;
      margin: 0 24px 0 0;
    }

    ${MediaQueries.mbDown} {
      .text {
        display: none;
      }

      .banner {
        min-width: 100px;
        max-width: 100px;
        height: 100px;
        margin: 0 16px 0 0;
      }

      .specialty {
        display: none;
      }
    }
  }

  &[data-direction='vertical'] {
    flex-direction: column;
    padding: 0 0 0 0;

    .label {
      margin-top: 16px;
    }

    &[data-size='sm'] {
      .banner {
        height: 150px;
      }

      .text {
        display: none;
      }

      .title {
        margin-top: 0;
        margin-bottom: 12px;
      }
    }

    &[data-size='lg'] {
      .banner {
        max-height: 360px;

        ${MediaQueries.mbDown} {
          max-height: 192px;
        }
      }

      .text {
        display: -webkit-box;
        margin-top: 8px;
        margin-bottom: 16px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }

    .banner {
      min-width: 100%;
      height: 468px;
      margin: 0;
    }
  }

  &[data-is-expert] {
    .inner-content label.text {
      display: none;
    }

    .specialty > [data-tag='h6'] {
      font-size: 13px;
      font-weight: 400;
      line-height: 19px;
    }

    &[data-size='md'] .footer .author .avatar {
      margin-right: 8px;
    }

    ${MediaQueries.mbUp} {
      &[data-size='md'] .footer .author .avatar {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }

    .title {
      margin-bottom: 16px;
      ${MediaQueries.mbDown} {
        margin-bottom: 8px;
      }
    }

    &[data-direction='horizontal'] {
      .footer {
        margin-top: auto;
      }
    }
  }
`

export const StyledAuthor = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;

  .author {
    display: flex;
    align-items: center;

    .avatar,
    .expert-avatar {
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .poster {
      display: flex;
      align-items: center;
      white-space: pre-wrap;

      p {
        display: -webkit-box;
        overflow: hidden;
        //font-size: 11px;
        -webkit-box-orient: vertical;
        font-weight: 400;
        -webkit-line-clamp: 3;

        a {
          color: ${(props: ThemeProps) => props.theme.colors.gray800};
          text-decoration: none;
        }

        span {
          color: ${(props: ThemeProps) => props.theme.colors.gray400};
        }
      }
    }

    .expert {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      a {
        text-decoration: none;
      }
    }

    .avatar {
      width: 24px;
      height: 24px;
      margin-right: 8px;

      ${MediaQueries.mbDown} {
        margin-right: 4px;
      }

      &[data-size='lg'] {
        ${MediaQueries.mbUp} {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }
      }
    }

    .expert-avatar {
      ${MediaQueries.mbUp} {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }

      ${MediaQueries.mbDown} {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }

      &[data-size='lg'] {
        ${MediaQueries.mbUp} {
          width: 64px;
          height: 64px;
          margin-right: 16px;
        }

        ${MediaQueries.mbDown} {
          width: 40px;
          height: 40px;
          margin-right: 16px;
        }
      }
    }
  }
`
