import React, { useContext, useEffect, useMemo, useState } from 'react'

import { ImageWrap } from '@hhgtech/hhg-components'
import { PATHS } from 'api/paths'
import { callApiWithAuth, getSSOPath } from 'api/utils'
import {
  ArticleCard,
  Props as ArticleCardProps,
} from 'components/molecules/articleCard'
import { getArticleCardDisplayAuthor } from 'components/pages/article/utils'
import { domainLocales } from 'domainLocales'
import innertext from 'innertext'
import { Article } from 'interfaces/types'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { AppContext } from 'state/appContext'

import { actionTypes } from '../../../state/actionTypes'
import { StyledAvatarReviewer, StyledTinyBadge } from './index.styled'

type Props = Omit<ArticleCardProps, 'avatarImg' | 'authorName'> & {
  article: Article
  avatarImg?: JSX.Element
  authorName?: string
  authorLink?: string
  title: string
  text: string
  headingType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  avatarImgRenderer?: (avatar: string, name: string) => JSX.Element
  disabledBadge?: boolean
  headingAs?: string
}

export const ArticleCardWrapper = ({
  article,
  avatarImg,
  avatarImgRenderer,
  authorName,
  authorLink,
  title,
  text,
  disabledBadge,
  ...rest
}: Props) => {
  const { formatMessage: f } = useIntl()
  const { state, dispatch } = useContext(AppContext)
  const { userInfo } = state || {}
  const { bookmarks = [] } = userInfo || {}
  const NEXT_PUBLIC_SSO = process.env.NEXT_PUBLIC_SSO
  // const BOOKMARK_HOST_NAME = 'marrybaby.vn'
  const [isBookmarked, setIsBookmarked] = useState(
    bookmarks.indexOf(article.id) !== -1,
  )
  const [isBookmarkDisplayed, setIsBookmarkDisplayed] = useState(false)
  const { locale } = useRouter()
  const sL = locale || 'vi-VN' // standardized locale
  const author = getArticleCardDisplayAuthor(article)
  const bookmarkArticle = (id: string | number) => {
    if (userInfo?.id) {
      setIsBookmarked((isBookmarked) => !isBookmarked)
      if (isBookmarked) {
        ;(async () => {
          const { _status } = await callApiWithAuth(
            getSSOPath(PATHS.DELETE_BOOKMARK),
            'DELETE',
            {
              data: {
                host_name: domainLocales[sL].replace('www.', ''),
                post_ids: [id],
              },
            },
          )
          if (_status === 1) {
            dispatch({
              type: actionTypes.REMOVE_BOOKMARKS,
              payload: {
                bookmarks: [id],
                bookmarkId: id,
              },
            })
          }
        })()
      } else {
        ;(async () => {
          const { _status, _data } = await callApiWithAuth(
            getSSOPath(PATHS.CREATE_BOOKMARK),
            'POST',
            {
              data: {
                host_name: domainLocales[sL].replace('www.', ''),
                post_ids: [id],
              },
            },
          )
          if (_status === 1) {
            dispatch({
              type: actionTypes.ADD_BOOKMARKS,
              payload: {
                bookmarks: [id],
                bookmarkId: id,
                isFirstTimeBookmark: _data.first_time_bookmark,
              },
            })
          }
        })()
      }
    } else {
      const currentLocation = window.location.href
      const returnUrlEncode = encodeURIComponent(
        `${currentLocation}${
          currentLocation.includes('?') ? '&' : '?'
        }source=bookmark,${id}`,
      )
      window.location.href = NEXT_PUBLIC_SSO
        ? `${NEXT_PUBLIC_SSO}?returnUrl=${returnUrlEncode}`
        : currentLocation
    }
  }

  const { reviewContent, avatar, badge, avatarLink } = useMemo(() => {
    const { reviewed: articleReviewed } = article
    if (articleReviewed?.author) {
      const { fact_check, editor, is_author } = articleReviewed
      if (articleReviewed?.expert) {
        if (is_author) {
          return {
            reviewContent: f(
              {
                id: 'articlePage.articleWrittenBy',
                defaultMessage: 'Written by {name}',
              },
              {
                name: articleReviewed?.expert?.display_name,
              },
            ),
            avatar: articleReviewed?.expert?.avatar_thumbnail || '',
            avatarLink: articleReviewed?.expert?.profile_url,
            badge: articleReviewed?.expert?.role || '',
          }
        } else {
          return {
            reviewContent: f(
              {
                id:
                  articleReviewed?.expert?.role === 'medical'
                    ? 'articlePage.reviewed.medically'
                    : 'articlePage.reviewed.expertly',
              },
              {
                name: articleReviewed?.expert?.display_name,
              },
            ),
            avatar: articleReviewed?.expert?.avatar_thumbnail || '',
            avatarLink: articleReviewed?.expert?.profile_url,
            badge: articleReviewed?.expert?.role || '',
          }
        }
      } else {
        const factCheckReviewer =
          editor && editor?.user_id !== articleReviewed?.author?.user_id
            ? editor
            : fact_check
        return {
          reviewContent: f(
            { id: 'articlePage.reviewed.factCheck' },
            {
              name: factCheckReviewer?.display_name,
            },
          ),
          avatar: factCheckReviewer?.avatar_thumbnail || '',
          avatarLink: `https://${domainLocales[sL]}${
            factCheckReviewer?.profile_url || ''
          }`,
          badge: 'fact-check',
        }
      }
    }
    return { reviewContent: null, avatar: null, avatarLink: null, badge: null }
  }, [])

  useEffect(() => {
    setIsBookmarkDisplayed(true)
  }, [])

  useEffect(() => {
    setIsBookmarked(bookmarks.indexOf(article.id) !== -1)
  }, [bookmarks.length])

  return (
    <ArticleCard
      dataEventCategoryForBookmark="Bookmark"
      dataEventActionForBookmark="Click"
      dataEventLabelForBookmark={`https://${domainLocales[sL]}${article.permalink}`}
      {...rest}
      isPremium={article.isPremium}
      title={title ? innertext(title) : ''}
      text={text ? innertext(text) : ''}
      avatarImg={
        <a
          href={
            avatarLink ||
            `https://${domainLocales[sL]}${authorLink || author.permalink}`
          }
        >
          {avatar ? (
            <StyledAvatarReviewer>
              {(avatarImgRenderer &&
                avatarImgRenderer(author.avatar, author.name)) || (
                <ImageWrap
                  src={avatar}
                  className="avatar"
                  alt={f({ id: 'articlePage.authorAvatarAlt' })}
                />
              )}
              {badge && !disabledBadge && (
                <StyledTinyBadge className="badge">
                  <ImageWrap
                    src={`/images/reviewer-${badge}.svg`}
                    alt="badge"
                  />
                </StyledTinyBadge>
              )}
            </StyledAvatarReviewer>
          ) : (
            avatarImg ||
            (avatarImgRenderer &&
              avatarImgRenderer(author.avatar, author.name)) || (
              <ImageWrap src={author.avatar} alt={author.name} />
            )
          )}
        </a>
      }
      authorName={
        reviewContent ||
        authorName ||
        f(
          {
            id:
              author.type === 'expert'
                ? 'articlePage.articleWrittenBy'
                : 'articlePage.articleReviewerBy',
          },
          {
            name: author.name,
          },
        )
      }
      authorLink={avatarLink || authorLink || author.permalink}
      isBookmarkDisplayed={isBookmarkDisplayed}
      isBookmark={isBookmarked}
      onBookmark={() => bookmarkArticle(article.id)}
    />
  )
}
